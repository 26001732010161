@import 'src/styles/_variables.scss';

.verifyBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  margin: 0 auto;
  .verifyInput {
    &:disabled {
      border: none;
      background-color: transparent;
      font-weight: 700;
      font-size: 16px;
    }
    &::placeholder {
      color: $lightDarkGray;
    }
    border-radius: 5px;
    padding: 3px;
  }
  .verifyBtn {
    padding: 5px;
    background-color: $shuttleOrange;
    border: none;
    border-radius: 5px;
    min-width: 45px;
    margin: 0 5px;
    &:hover {
      background-color: $lightDarkGray;
    }
    .bp3-icon {
      margin: 0;
    }
  }
}
