@font-face {
  font-family: 'Bahij TheSansArabic';
  src: local('Bahij TheSansArabic Regular'),
    url('../assets/fonts/Bahij_TheSansArabic-Plain.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bahij TheSansArabic';
  src: local('Bahij TheSansArabic Bold'),
    url('../assets/fonts/Bahij_TheSansArabic-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
