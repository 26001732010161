@import 'src/styles/_variables.scss';

.container {
  margin-bottom: 35px !important;
  .label {
    font-size: 16px;
    color: $text;
    line-height: 1.25;
    cursor: pointer;
    margin-bottom: 5px;
  }
}
