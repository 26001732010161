@import 'src/styles/_variables.scss';

.inputBlock {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  height: 50px;
  position: relative;
  input {
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    color: $shuttleBlack;
    outline: none;
    padding-left: 5px;
    border-bottom: 1px solid $disabled;
    align-self: flex-end;
    &::placeholder {
      transition: all 0.25s ease;
      color: $disabled;
      padding-bottom: 10px;
    }
    &:focus {
      border-bottom: 1px solid $shuttleOrange;
      &::placeholder {
        font-size: 13px;
        color: $lightDarkGray;
      }
    }
  }
  @media screen and (max-width: 950px) {
    width: 300px;
  }
  label {
    position: absolute;
    top: 0;
    left: 5px;
    color: $disabled;
  }
  .requiredLabel {
    position: absolute;
    top: 15px;
    right: 5px;
    color: $lightDarkGray;
  }
  .icon {
    position: absolute;
    right: 5px;
    top: 18px;
    color: $disabled;
  }
}
.subInputBlock {
  width: 460px;
  @media screen and (max-width: 950px) {
    width: 260px;
  }
}
.btnBrowse {
  display: inline-flex;
  justify-content: center;
  font-size: 17px;
  padding: 10px 0;
  min-width: 140px;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  border: none;
  color: $shuttleWhite;
  background-color: $shuttleOrange;
  margin-bottom: 0;
}
.photoFile {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.photoBox {
  width: 49%;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 950px) {
    width: 310px;
  }
}
.price {
  align-items: flex-end;
}
.helperText {
  color: $shuttleBlueShadow;
}
.helperTextError {
  color: $shuttleError;
}
