@import 'src/styles/_variables.scss';

.marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: $shuttleOrange;
  color: $shuttleWhite;
  cursor: pointer;
  padding: 20px;
}

.locationName {
  text-align: center;
  margin-top: 5px;
}
