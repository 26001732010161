@import 'src/styles/_variables.scss';

.container {
  padding: 50px 0;
  text-align: center;
  .title {
    text-align: center;
    margin-bottom: 15px;
  }
}
