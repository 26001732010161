@import 'src/styles/_variables.scss';

.headerBlock {
  display: flex;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: center;
  color: $shuttleBlue;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
}
.btn {
  margin-left: auto;
  cursor: pointer;
  padding: 10px 25px;
}

.link {
  background: none $shuttleOrange;
  padding: 10px 25px;
  font-size: 17px;
  color: $shuttleWhite;
  border-radius: 50px;
  &:hover {
    color: $shuttleWhite;
  }
}
