@import 'src/styles/_variables.scss';

.checkBox {
  input {
    &:checked ~ [class='bp3-control-indicator'] {
      background-color: $shuttleOrange !important;
      box-shadow: 0px 0px 0px 1px $shuttleOrange !important;
    }
  }
}
