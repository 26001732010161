@import '../../../styles//variables';

.list {
  list-style: none;

  .listItem {
    span:first-child {
      color: $lightDarkGray;
    }
    span:last-child {
      color: $text;
    }
  }
  .itemTitle {
    display: inline-block;
    margin: 0 5px;
  }
}

.contactName {
  padding: 0 10px 0 10px;
}
