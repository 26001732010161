@import 'src/styles/_variables.scss';

.container {
  display: flex;
  flex-direction: column;
  .list {
    display: flex;
    flex-direction: column;
    .listItem {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-self: flex-start;
      padding: 5px 10px;
      min-width: 300px;
      max-width: 300px;
    }

    .itemRow {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .itemRow [class^='bp3-icon'] {
      margin-right: 15px;
    }

    .infoSide {
      min-width: 260px;
      max-width: 260px;
      overflow: hidden;
      .mapBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        min-width: 20px;
        background-color: $shuttleOrange;
        border-radius: 6px;
        cursor: pointer;
        margin-right: 10px;
        [class^='bp3-icon'] {
          margin-right: 0;
        }
      }
    }

    .actionsSide {
      display: flex;
      flex-direction: column;
      min-width: 20px;
      max-width: 20px;
    }

    .listItem:not(:last-child) {
      border-bottom: 1px solid lightgray;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .itemRow:not(:last-child) {
      margin-bottom: 5px;
    }

    .itemLabel {
      width: 225px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
