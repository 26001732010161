@import 'src/styles/_variables.scss';

.modal {
  padding: 30px !important;
  background-color: $shuttleWhite !important;
}

.header {
  font-size: 18px;
  font-weight: bold;
  color: $shuttleBlue;
  line-height: 1.5;
  margin-bottom: 20px;
}

.labelTip {
  display: block;
  font-size: 16px;
  color: $text;
  line-height: 1.25;
  border-radius: 0px;
  margin-bottom: 20px;
  text-decoration: underline;
}

.inputLabel {
  font-size: 14px;
  color: $disabled;
  line-height: 1.33;
  margin-bottom: 5px;
}

.fileInput {
  background-color: $shuttleWhite;
  border: 1px solid $disabled;
  border-radius: 4px;
  box-shadow: none !important;
  outline: none !important;
  height: 38px !important;
}

.fileInput [class='bp3-file-upload-input'] {
  height: 100% !important;
}

.fileInput [class='bp3-file-upload-input']::after {
  font-size: 17px !important;
  color: $shuttleBlack !important;
  text-align: center !important;
  line-height: 1.33 !important;
  background-color: $shuttleBlueShadow !important;
  border-radius: 0px 3px 3px 0px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  height: 100%;
  box-shadow: none !important;
  width: 120px !important;
  background-image: none !important;
}

.formBtn {
  flex-basis: 100%;
  margin-top: 20px;
  text-align: right;
}

.cancelBtn,
.saveBtn {
  display: inline-block;
  border: none;
  font-size: 17px;
  padding: 10px 0;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.cancelBtn {
  color: $shuttleBlack;
  margin-right: 0px;
}

.saveBtn {
  background-color: $shuttleOrange;
  color: $shuttleWhite;
  border-radius: 50px;
  cursor: pointer;
}

.saveBtn:focus {
  outline: none;
}

.saveBtn:disabled {
  background-color: $shuttleBlueShadow;
  color: $disabled;
  cursor: default;
}
