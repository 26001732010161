* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* a:hover {
  text-decoration: none;
  color: inherit;
}
body {
  height: auto;
}
