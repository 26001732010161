@import 'src/styles/_variables.scss';

.loader {
  display: inline-block;
  .spinner [class*='bp3-spinner-head'] {
    stroke: $shuttleOrange;
  }
  .loadingText {
    margin-top: 20px;
    text-align: center;
  }
}
