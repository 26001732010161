@import 'src/styles/_variables.scss';
.container {
  position: relative;
  padding: 30px;
  margin-bottom: auto;
  height: 100vh;
  border-bottom: 1px dotted $shuttleBlack;
}
.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.infoBlock {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.infoTable {
  margin-top: 10px;
  border: 2px solid $darkGrey;
  border-spacing: 0px;
  width: 40%;
  .cell {
    padding: 3px;
    border: 2px solid $darkGrey;
    width: 30%;
    &.empty {
      border-top: 2px solid $shuttleWhite;
      border-left: 2px solid $shuttleWhite;
    }
    &.info {
      width: 30%;
      font-size: 12px;
      font-weight: bold;
      & + .cell {
        font-size: 12px;
      }
    }

    &.hide {
      border-top: 2px solid $shuttleWhite;
      border-bottom: 2px solid $shuttleWhite;
    }
  }
}
.table {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  border-spacing: 0px;
  .tableHead {
    padding: 3px;
    font-weight: bold;
    border: 2px solid $darkGrey;
  }
  .body,
  .row {
    width: 100%;
  }
  .cell {
    border: 2px solid $darkGrey;
    padding: 0 3px;
    &.shortInfo {
      width: 5%;
      text-align: right;
    }
  }
}
