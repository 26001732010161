@import 'src/styles/_variables.scss';

.actionsContainer {
  margin-top: 30px;
  .actionButton {
    padding: 25px 15px;
    font-size: 16px;
    color: $shuttleWhite;
    background-color: $lightDarkGray;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &.selected {
      background-color: $shuttleOrange;
    }
  }
}
