@import 'src/styles/_variables.scss';

.pickupItem {
  text-align: left;
  background-color: $shuttleWhite;
  padding: 20px;
  border-radius: 6px;
  box-shadow: $shuttleBlackShadow 0px 0px 16px 0px;
  margin-top: 30px;
  .pickupOrderBtn {
    padding: 10px 20px;
    background-color: $shuttleSuccess;
    color: $shuttleWhite;
    width: 150px;
    margin: 0 auto;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
}

.btnGroup {
  margin-top: 20px;
}
