@import 'src/styles/_variables.scss';
.modal {
  width: 50%;
  min-width: 300px;
  margin: auto;
  padding: 10px 20px 20px;
  background-color: $shuttleWhite;
  border-radius: 60px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
}
.closeBtn {
  margin: 10px 10px 10px auto;
  cursor: pointer;
}
.title {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 15px;
}
.text {
  padding: 30px 5px;
}
.btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 7px 17px;
}
.btnPrimary {
  background-color: $shuttleOrange;
}

.btnSecondary {
  background-color: $background;
}
.box {
  padding: 15px 0;
}
.scanBox {
  width: 260px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: $shuttleWhite;
  background-image: linear-gradient(
      $shuttleOrange 40px,
      transparent 40px,
      transparent 220px,
      $shuttleOrange 220px
    ),
    linear-gradient(
      90deg,
      $shuttleOrange 40px,
      transparent 40px,
      transparent 220px,
      $shuttleOrange 220px
    ),
    linear-gradient(
      $shuttleOrange 40px,
      transparent 40px,
      transparent 220px,
      $shuttleOrange 220px
    ),
    linear-gradient(
      90deg,
      $shuttleOrange 40px,
      transparent 40px,
      transparent 220px,
      $shuttleOrange 220px
    );
  background-size: 30px 260px, 260px 30px, 30px 260px, 260px 30px;
  background-position: 0 0, 0 0, 100% 100%, 100% 100%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  border-radius: 35px;
}
.scan {
  width: 240px;
  height: 240px;
  object-fit: cover;
  border-radius: 25px;
  border: 15px solid $shuttleWhite;
}
.scanLoader {
  text-align: center;
  color: $lightDarkGray;
}
.white {
  color: $shuttleWhite;
}
.warning {
  color: $lightDarkGray;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}
