@import 'src/styles/_variables.scss';

.marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 75px;
  height: 75px;
  border-radius: 50px;
  background-color: $shuttleOrange;
  color: $shuttleWhite;
  cursor: pointer;
  padding: 20px 0;
}

.mapBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  margin-left: 20px;
  background-color: $shuttleOrange;
  border-radius: 6px;
  cursor: pointer;
}
