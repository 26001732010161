.buttonsGroup {
  display: flex;
  margin-top: 70px;
  text-align: right;
  *:not(:first-child) {
    margin-left: 10px;
  }
  &.mobile {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .btnPrintAWBS {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}
