@import 'src/styles/_variables.scss';

.navigation {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .start {
    border-radius: 7px;
    padding: 10px 30px;
    margin-right: 50px;
  }
  .cancel,
  .skip,
  .ok,
  .end {
    padding: 10px 30px;
    font-size: 17px;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    color: $disabled;
    text-decoration: underline;
  }
  .ok,
  .end {
    background-color: $shuttleOrange;
    border-radius: 7px;
    text-decoration: none;
    color: $shuttleWhite;
  }
  .ok {
    padding: 5px 15px;
  }
  .end {
    min-width: 100px;
    padding: 5px 15px;
  }
  .skip {
    font-size: 14px;
    padding: 0;
    color: $shuttleWhite;
    margin-left: 10px;
  }
  .dots {
    width: 150px;
    margin-right: auto;
    border: 0.5px solid $shuttleWhite;
    border-radius: 40px;
  }
}
.welcome {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.5px;
  word-spacing: 7px;
  color: $shuttleBlue;
  .welcomeTitle {
    font-size: 30px;
    margin: 25px 0;
    letter-spacing: 0;
    text-shadow: 0px 0px 1px $shuttleBlue;
    .welcomeAccent {
      color: $shuttleOrange;
      text-shadow: 0px 0px 1px $shuttleOrange;
      display: inline-block;
      margin-right: 10px;
    }
  }
}
.step {
  color: $shuttleWhite;
}
.stepTitle {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}
.stepContent {
  font-size: 14px;
}
.icon {
  display: block;
  transform: rotate(90deg);
  width: 50px;
  height: 50px;
}
.acknolwedge {
  border-radius: 7px;
  padding: 5px 15px;
  font-size: 12px;
}
