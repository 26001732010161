@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import 'src/styles/_variables.scss';

.title,
.subtitle {
  color: $shuttleBlack;
}

.title {
  font-size: 16px;
}

.subtitle {
  font-size: 12px;
}

.cards {
  background-color: $yellow;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  min-height: 70px;
  max-width: 170px;
  padding: 10px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }

  span {
    display: block;
    font-weight: normal;
  }
  p {
    font-weight: bold;
    margin-bottom: 0;
  }
}

.orderCard {
  background-color: $background !important;
}
.customerCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  color: black !important;
  text-decoration: none !important;
}

.disabled {
  background-color: $disabled;
}
