@import 'src/styles/_variables.scss';

.mapBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  background-color: $shuttleOrange;
  border-radius: 6px;
  cursor: pointer;
}
