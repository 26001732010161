@import 'src/styles/_variables.scss';

.modal {
  background-color: $shuttleWhite !important;
  padding: 20px;
  .modalHeader {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
  }
  [class*='inputBlock'] {
    width: 100%;
    &:nth-child(3) {
      margin-bottom: 20px;
    }
  }
  .btnGroup {
    display: flex;
    button:nth-child(1) {
      margin-right: 20px;
      margin-left: auto;
    }
  }
}
