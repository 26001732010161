@import 'src/styles/_variables.scss';

.languageToogleBtn {
  margin: 0 0 0 15px !important;
  input {
    &:checked ~ [class='bp3-control-indicator'] {
      background-color: $shuttleOrange !important;
    }
    & ~ [class='bp3-control-indicator'] {
      background-color: $shuttleOrange !important;
      color: $shuttleWhite;
      outline: none !important;
    }
  }
  [class='bp3-control-indicator'] {
    font-size: 25px;
  }
}
