@import 'src/styles/_variables.scss';

.suggestContainer {
  display: flex;
  margin-bottom: 20px;

  .suggest {
    display: flex;
    align-items: center;
    width: 300px;
    height: 40px;
    border-bottom: 1px solid $disabled;
    margin-right: 20px;
  }

  .suggest [class^='bp3-popover-target'] {
    width: 100%;
  }

  .suggest input {
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    height: 40px;
    width: 270px;
    position: relative;
    vertical-align: top;
    font-size: 16px;
    color: $shuttleBlack;
    outline: none;
    box-shadow: none;
    width: 100%;
  }

  .suggest input:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid $shuttleOrange;
  }
}
