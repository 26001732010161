@import 'src/styles/_variables.scss';

.ordersList {
  max-height: 200px;
  min-height: max-content;
  overflow-y: scroll;
  .listItem {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid $shuttleBlueShadow;
    font-size: 16px;
    color: $text;
    line-height: 1.25;
    .orderNumber {
      max-width: 80px;
      width: 100%;
    }
    .orderReceiver,
    .orderLocation {
      max-width: 200px;
      width: 100%;
    }
    .removeOrderBtn {
      margin-left: auto;
      cursor: pointer;
    }
  }
}
