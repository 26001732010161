@import 'src/styles/_variables.scss';

.button {
  display: inline-flex;
  justify-content: center;
  font-size: 17px;
  padding: 10px 0;
  min-width: 140px;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: $shuttleWhite;
  &.dismiss {
    color: $shuttleBlack;
    border: 1px solid $shuttleBlack;
  }
  &.action {
    color: $shuttleWhite;
    background-color: $shuttleOrange;
  }
  &.actionBorder {
    color: $shuttleOrange;
    background-color: $shuttleWhite;
    border: 1px solid $shuttleOrange;
    font-size: 14px;
  }
  &.disabled {
    color: $shuttleWhite;
    background-color: $lightDarkGray;
    cursor: default;
  }
}
