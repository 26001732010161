@import 'src/styles/_variables.scss';

.headerBlock {
  display: flex;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: center;
  color: $shuttleBlue;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;
}

.routeBtn {
  margin-left: auto;
  cursor: pointer;
  background: none $shuttleOrange;
  padding: 10px 25px;
  font-size: 17px;
  color: $shuttleWhite;
  border-radius: 50px;
}

.routeBtn:hover {
  color: $shuttleWhite;
}
