@import 'src/styles/_variables.scss';

.qrcodeModal {
  padding: 20px;
}
.qrcodeHeader {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.iconClose {
  cursor: pointer;
}
.qrcodeBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.qrcodeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: $shuttleOrange;
  border-radius: 6px;
  cursor: pointer;
  &:first-child {
    margin-bottom: 40px;
  }
}
