@import 'src/styles/_variables.scss';

.backBox .backBtn {
  color: $shuttleBlack;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  min-width: 20%;
  background-color: transparent;
  padding: 0px 3px;
  margin-right: auto;
  margin-bottom: 10px;
  &:hover {
    outline: 2px solid $shuttleOrange;
    border-radius: 5px;
  }
}
