@import 'src/styles/_variables.scss';

.mapBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  background-color: $shuttleOrange;
  border-radius: 6px;
  cursor: pointer;
}
.fullscreen {
  background-color: $shuttleWhite;
  display: block;
  padding: 10px;
  color: $text;
  position: absolute;
  top: 60px;
  right: 30px;
  z-index: 100000;
}
