@import 'src/styles/_variables.scss';

.container {
  padding: 50px 0;
  text-align: center;
  .header {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  .title {
    text-align: center;
    margin-bottom: 15px;
  }
  .scanBtn {
    width: 50%;
    background-color: $lightDarkGray;
    color: $shuttleWhite;
    border: none;
    padding: 15px 15px;
    margin-bottom: 35px;
    border-right: 1px solid $shuttleWhite;
    border-radius: 0;
    .bp3-icon {
      margin: 0;
    }
    &:hover {
      background-color: $shuttleOrange;
      background-color: $lightDarkGray;
    }
  }
  .btnActive {
    background-color: $shuttleOrange;
  }
  .sizesContainer {
    margin-bottom: 20px;
  }
  .sizeList {
    margin-top: 10px;
    .sizeItem {
      padding: 25px 15px;
      font-size: 16px;
      background-color: $lightDarkGray;
      color: $shuttleWhite;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.selected {
        background-color: $shuttleOrange;
      }
    }
  }

  .openBtn {
    margin-top: 20px;
    text-align: center;
  }
}
