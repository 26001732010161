@import 'src/styles/_variables.scss';

.marker {
  border-radius: 10px;
  background-color: $shuttleError;
  border: 1px solid $shuttleWhite;
  color: $shuttleWhite;
  cursor: pointer;
  padding: 5px 10px;
}
.label {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
}
.position {
  border-radius: 10px;
  font-size: 11px;
  color: $shuttleError;
  background-color: $shuttleWhite;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.error {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  color: $shuttleError;
}
