@import 'src/styles/_variables.scss';

.modal {
  padding: 20px;
  text-align: center;
  .title {
    margin-bottom: 20px;
  }
  .text {
    font-size: 18px;
  }
  .btnGroup {
    display: flex;
    justify-content: center;
    button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
.boxIcon {
  text-align: center;
  margin-bottom: 15px;
}
.icon {
  color: $shuttleOrange;
}
