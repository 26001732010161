@import 'src/styles/_variables.scss';

.mapBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  min-width: 38px;
  background-color: $shuttleOrange;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 20px;
}

.addressBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  [class*='inputBlock'] {
    width: 100%;
  }
  .editBtn {
    color: $shuttleOrange;
    cursor: pointer;
  }
}

.zoneList {
  padding: 10px;
  border: 1px dotted $disabled;
  .zoneItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $disabled;
  }
}
.visibleToggleBtn {
  margin: 0 0 0 20px !important;
  input {
    &:checked ~ [class='bp3-control-indicator'] {
      background-color: $shuttleOrange !important;
    }
    & ~ [class='bp3-control-indicator'] {
      // background-color: $shuttleOrange !important;
      color: $shuttleBlack;
      outline: none !important;
    }
  }
  [class='bp3-control-indicator'] {
    font-size: 25px;
  }
}
.qrBox {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
}
.form {
  display: inline-block;
  margin-top: 10px;
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 950px) {
    margin-top: 20px;
  }
}
