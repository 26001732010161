@import 'src/styles/_variables.scss';

.modal {
  padding: 20px;
  text-align: center;
  .text {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
.boxIcon {
  text-align: center;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    margin: 0;
    margin-left: 10px;
  }
}
.icon {
  color: $shuttleOrange;
}
