$yellow: #eb6500;
$shuttleOrange: #eb6500;
$shuttleOrangeShadow: rgba(235, 101, 0, 0.2);
$shuttleLight: #ffeadb;
$shuttleBlue: #102632;
$shuttleBlueShadow: rgba(16, 38, 50, 0.2);
$shuttleBlueLight: rgba(16, 38, 50, 0.9);
$shuttleBlack: #000;
$shuttleBlackShadow: rgba(0, 0, 0, 0.07);
$shuttleWhite: #fff;
$shuttleYellow: yellow;
$shuttleSuccess: #7bff00;
$shuttleError: #ff2222;
$shuttleErrorShadow: rgba(255, 34, 34, 0.4);
$background: #edeef1;
$text: #3e3e3e;
$darkGrey: #252525;
$lightDarkGray: #c0c0c0;
$black: #947878;
$disabled: rgba(16, 38, 50, 0.5);
$shuttlePurple: #800080;
