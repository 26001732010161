@import 'src/styles/_variables.scss';

// .clientSelect {
// }

.modal {
  padding: 10px 20px;
  align-items: center;
  [class^='Select_inputBlock'] {
    width: 100%;
  }
}

.modalHeader {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
}

.itemsList {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $disabled;
  border-bottom: 1px solid $disabled;
  min-height: 500px;
  max-height: 500px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.listItem {
  display: flex;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 10px;
  border-bottom: 1px solid $shuttleOrange;
  // min-height: 40px;
  max-height: 100px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.spinner [class*='bp3-spinner-head'] {
  stroke: $shuttleOrange;
}

.fullLoadedLabel {
  margin-top: auto;
  text-align: center;
}

.cancelBtn {
  align-self: center;
  padding: 15px 50px;
  border-radius: 50px;
  border: 1px solid $shuttleBlack;
  background-color: $shuttleWhite;
  cursor: pointer;
}

.removeIcon {
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.multi {
  position: relative;
  flex-direction: column;
}
.multiItem {
  position: relative;
}
.panel {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin: 10px 0;
}

.dateRangeFilterContainer {
  margin-bottom: 10px;
}
