@import 'src/styles/_variables.scss';

.container {
  display: block;
  padding: 40px 60px;
  width: 100%;
  @media screen and (max-width: 950px) {
    padding: 40px 10px;
  }
}

.form {
  display: inline-block;
  margin-top: 40px;
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 950px) {
    margin-top: 20px;
  }
}
.toggleBtn {
  margin: 30px 0 15px 5px !important;
  width: 200px;
  font-size: 16px;
  input {
    &:checked ~ [class='bp3-control-indicator'] {
      background-color: $shuttleOrange !important;
    }
    & ~ [class='bp3-control-indicator'] {
      background-color: $lightDarkGray !important;
      color: $shuttleWhite;
      outline: none !important;
    }
  }
  [class='bp3-control-indicator'] {
    font-size: 25px;
  }
}
.errorStyle {
  color: $shuttleError;
}
