.fallback {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 100;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/images/ShttleBackground2.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: -100px;
}

.fallback img {
  width: 300px !important;
  height: 150px !important;
}
