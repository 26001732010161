@import 'src/styles/_variables.scss';

.inputBlock {
  position: relative;
  width: 500px;
  select {
    width: 100%;
    border: none;
    border-bottom: 1px solid $disabled;
    padding: 20px 0 10px 3px;
    color: $shuttleBlue;
    font-size: 16px;
    background-color: transparent;
    &:focus {
      outline: none;
      border-bottom: 1px solid $shuttleOrange;
    }
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
  }
  .requiredLabel {
    position: absolute;
    top: 15px;
    right: 25px;
    color: $disabled;
  }
  @media screen and (max-width: 950px) {
    width: 300px;
  }
}

.reportIssueSelect {
  width: 100% !important;
}
