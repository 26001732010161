.btnOpenModal {
  width: 200px;
  margin-left: auto;
  font-size: 14px;
  padding: 5px 7px;
}
.modal {
  align-items: center;
  [class^='Select_inputBlock'] {
    width: 100%;
  }
}
